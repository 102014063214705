import React from 'react';
import { Avatar } from '../avatar/avatar';
import styles from './contactConnectCard.module.scss';
import { MdLink as LinkIcon } from 'react-icons/md';
import logo from 'assets/logo.png';
import { Heading } from '../heading/heading';
import { Button } from '../button/button';
import { ContactFormContainer } from '../../containers/contactFormContainer/contactFormContainer';
// import { Button } from 'reactstrap';

export const ContactConnectCard = (props) => {
  const { avatar, name, id, edit } = props;
  return (
    <>
      <div className={styles.contactConnectCard}>
        <div className="text-center">
          <div className="d-flex justify-content-center">
            <Avatar {...avatar} className={styles.avatar} size={50} />
            <div className="mt-3 px-2">
              <LinkIcon style={{ fontWeight: 'bold' }} />
            </div>
            <Avatar src={logo} className={styles.avatar} size={50} />
          </div>
          <div className="mt-3">
            <Heading> Connect with {name} on Holedo</Heading>
          </div>
          <div>
            <p className={styles.descColor}>
              You and {name} aren't yet connected on Holedo. Connect now and you'll be
              able to communicate with john directly from HL Messaging app. No need to write emails,
              simply message.
            </p>
          </div>
          <Button className="mb-2" inline style={{ background: '#394677', marginBottom: '4px' }}>
            Connect now
          </Button>
        </div>
      </div>
      {edit && <ContactFormContainer id={id} />}
    </>
  );
};
