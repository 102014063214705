import React, { useEffect, useState } from 'react';
import { ClientForm } from '@components/clientForm/clientForm';
import { Wizard } from '@components/wizard/wizard';
import { MdLocationCity as HotelIcon } from 'react-icons/md';
import { fetchAutoCompleteData } from '@services/autoCompleteService';
import { apiUrls } from '@constants/apiurls';
import clients from '@redux/modules/clients';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import yupSchemaService from '@services/yupSchemaService';
import { Spinner } from '@components/loader/loader';
import { routesPath } from '@constants/routesPath';
import { useDispatch, useSelector } from 'react-redux';
import { updateWizardStep } from '@redux/common/commonSlice';
import { views } from '../../constants/views';
import { handleViewsData } from '../../redux/common/commonSlice';
import { OverlayContainer } from '../overlayContainer/OverlayContainer';

export const ClientCreationContainer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    countryOptions: [],
    clientTypeOptions: [],
    images: [],
  });

  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  const [clientCreation, clientCreationData] = clients.form().useClientscreateMutation();
  const [clientUpdate, clientUpdateData] = clients.form().useClientsupdateMutation();
  const [clientInlineUpdate, clientInlineUpdateData] = clients.form().useClientsupdateMutation();
  const [deleteClient, deleteClientData] = clients.delete().useClientsdeleteMutation();

  useEffect(() => {
    dispatch(
      updateWizardStep({
        step: 0,
      }),
    );
    getInitialData();
  }, []);

  const {
    data: clientData,
    error: clientError,
    isFetching: clientLoading,
  } = clients
    .list()
    .useClientsdetailQuery(
      { params: { id: params.clientID }, extendedPath: apiUrls.getClientDetail() },
      { skip: !params.clientID },
    );
  const {
    data: allClients
  } = clients.list().useClientslistQuery({params: { with_hierarchy: true }});

  useEffect(() => {
    if (clientCreationData.isSuccess) {
      toast.success('Client Created Successfully!');
      navigate(
        routesPath.addContacts(params?.clientID || clientCreationData?.data?.id),
        { replace: false }
      );
    } else if (clientCreationData.isError) {
      toast.error(clientCreationData.error.message || 'Error occurred');
    }
  }, [clientCreationData]);

  useEffect(() => {
    if (clientData?.data?.client_images?.length) {
      setState((prevState) => ({ ...prevState, images: clientData.data.client_images }));
    }
  }, [clientData]);

  useEffect(() => {
    if (clientUpdateData.isSuccess) {
      toast.success('Client Updated Successfully!');
      navigate(routesPath.addContacts(params?.clientID), { replace: false });
    } else if (clientUpdateData.isError) {
      toast.error('Failed to update');
    }
  }, [clientUpdateData]);

  useEffect(() => {
    if (clientInlineUpdateData.isSuccess) {
      toast.success('Client Updated Successfully!');
    } else if (clientInlineUpdateData.isError) {
      toast.error('Failed to update');
    }
  }, [clientInlineUpdateData]);

  useEffect(() => {
    if (clientError) {
      toast.error(clientError.message || 'Error occurred');
    }
  }, [clientError]);

  useEffect(() => {
    if (deleteClientData?.isSuccess) {
      toast.success('Client Successfully Deleted');
      dispatch(handleViewsData(null));
      navigate(routesPath.dashboard, { replace: true });
    } else if (deleteClientData?.error) {
      toast.error('Client Deletion Failed');
      dispatch(handleViewsData(null));
    }
  }, [deleteClientData]);

  const getInitialData = async () => {
    try {
      const autoCompletedFields = [
        { type: 'hotel', url: apiUrls.getHotelTypes() },
        { type: 'country', url: apiUrls.getCountries() },
      ];
      const promises = autoCompletedFields.map((field) =>
        fetchAutoCompleteData(field.type, field.url),
      );

      const response = await Promise.all(promises);
      setState((prevState) => ({
        ...prevState,
        countryOptions: response[1],
        clientTypeOptions: response[0],
      }));
    } catch (err) {
      toast.error('Failed to fetch initial data');
    }
  };

  const createClient = (values) => {
    values.body.client_images = [...state.images];
    
    if (params.clientID) {
      clientUpdate(values.body);
    } else {
      clientCreation(values);
    }
  };  

  const updateClient = (values) => {
    clientInlineUpdate(values.body);
  };

  const clientRemove = (client) => {
    dispatch(handleViewsData({ client, currentView: views.confirmationDialog }));
  };

  return (
    <>
      {clientLoading ? (
        <div className="text-center ">
          <Spinner height="100" width="100" />
        </div>
      ) : (
        <div className="client-creation-screen">
          <Wizard
            step={1}
            title={
              clientData
                ? 'Edit Client Profile'
                : "Let's get started\n Create a client profile."
            }
            icon={<HotelIcon size={25} />}
          >
            <ClientForm
              state={state}
              setState={setState}
              ClientTypeOptions={state.clientTypeOptions}
              countryOptions={state.countryOptions}
              createClient={createClient}
              updateClient={updateClient}
              clientRemove={clientRemove}
              loading={clientCreationData.isLoading || clientUpdateData?.isLoading || clientInlineUpdateData?.isLoading}
              clientData={clientData?.data || null} // Use clientData or null
              allClients={allClients?.data || []}
              yupSchema={yupSchemaService.clientCreationSchema()}
              isEdit={Boolean(params.clientID) && !clientError}
            />

          </Wizard>
        </div>
      )}
      {currentUIState?.currentView === views.confirmationDialog && (
        <OverlayContainer
          closeOverlay={() => {
            dispatch(handleViewsData(null));
          }}
          currentView={views.confirmationDialog}
          acceptConfirmation={() => {
            deleteClient({ body: { id: currentUIState.client.data.id } });
          }}
          declineConfirmation={() => dispatch(handleViewsData(null))}
        />
      )}
    </>
  );
};
