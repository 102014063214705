import React from 'react';
import classNames from 'classnames';
import styles from 'components/jobTalentSearchFilters/jobTalentSearchFilters.module.scss';
import formStyles from 'components/formColumns/forms.module.scss';
import jobStyles from 'components/jobForm/jobForm.module.scss';
import { SelectInput } from '../form/selectInput';
import { MemberShipGradesCheckBox } from '../memberShipGradesCheckBox/memberShipGradesCheckBox';
import { CheckBoxGroup } from '../form/checkBoxGroup';

export const JobTalentSearchFiltersFields = (props) => {
  const {
    isFetchingQualifications,
    isFetchingLanguages,
    isFetchingPositionLevels,
    isFetchingCountries,
    isFetchingMembershipGrade,
    positionLevels,
    languages,
    qualifications,
    countries,
    membershipGrades,
    handleExperienceChange,
  } = props;

  return (
    <div className={classNames(styles.compCardContent, styles.noPaddingTop)}>
      <form className={classNames('form', formStyles.form)}>
        <div className={classNames(formStyles.formInnerWrapper, 'form-inner-wrapper')}>
          <div className={classNames(styles.fieldWrapper)}>
            {isFetchingPositionLevels ? (
              <small>Loading Experiences...</small>
            ) : (
              <>
                <div className={styles.filterLabel}>Candidate Experience</div>
                <CheckBoxGroup
                  options={positionLevels}
                  name="Candidate_experience"
                  label="Candidate Experience"
                  type="checkbox"
                  onChange={handleExperienceChange}
                />
              </>
            )}
          </div>

          <div className={classNames(styles.fieldWrapper)}>
            {isFetchingQualifications ? (
              <small>Loading Qualifications...</small>
            ) : (
              <>
                <div className={styles.filterLabel}>Candidate Qualification</div>
                <CheckBoxGroup
                  options={qualifications}
                  name="Candidate_qualification"
                  label="Candidate Qualification"
                  type="checkbox"
                  onChange={handleExperienceChange}
                />
              </>
            )}
          </div>

          <div className={classNames(styles.fieldWrapper)}>
            {isFetchingLanguages ? (
              <small>Loading Languages...</small>
            ) : (
              <>
                <div className={styles.filterLabel}>Fluently speaks</div>
                <SelectInput
                  options={languages}
                  mode="multiple"
                  searchable
                  name="Candidate_language(s)"
                  label="Fluently speaks"
                  onChange={handleExperienceChange}
                />
              </>
            )}
          </div>

          <div className={classNames(jobStyles.fieldWrapper)}>
            {isFetchingCountries ? (
              <small>Loading countries... </small>
            ) : (
              <>
                <div className={styles.filterLabel}>Candidate country location</div>
                <SelectInput
                  options={countries}
                  name="Candidate_country_location"
                  label="Country , region or city"
                  onChange={handleExperienceChange}
                />
              </>
            )}
          </div>

          <div className={classNames(formStyles.formInnerWrapper, 'form-inner-wrapper')}>
            <span className={classNames(styles.inputTitle)}>Membership Grades</span>
            <div className={classNames(styles.textSmall)}>
              {'The grades a candidate has earned on Holedo. '}
              <a className={styles.textLink} href="#" target="_blank" rel="noopener noreferrer">
                Grades explained
              </a>
            </div>
            {isFetchingMembershipGrade ? (
              <small>Loading Membership Grades...</small>
            ) : (
              <div className={classNames(styles.fieldWrapper)}>
                <>
                  <MemberShipGradesCheckBox
                    options={membershipGrades}
                    name="Candidate_membership_grade"
                    label="Candidate Membership Grades"
                    type="radio"
                    onChange={handleExperienceChange}
                  />
                </>
              </div>
            )}
          </div>

          <div className={classNames(jobStyles.fieldWrapper)}></div>
        </div>
      </form>
    </div>
  );
};
