import React, { useState } from 'react';
import styles from './nestedDropdown.module.scss';

export const NestedDropdown = ({ clients, onSelection }) => {
  const [selectedValue, setSelectedValue] = useState('Select...');
  const [showMenu, setShowMenu] = useState(false);

  const handleSelection = (value, label) => {
    setSelectedValue(label); // Update the displayed value
    onSelection(value, label); // Notify parent about the selection
    setShowMenu(false); // Close dropdown menu
  };

  const renderNestedMenu = (clients) => {
    return clients.map((client) => (
      <li key={client.id} className={styles.dropdownItem}>
        <span
          onClick={(e) => {
            e.stopPropagation();
            handleSelection(client.id, client.title); // Select the client
          }}
        >
          {client.title}
        </span>
        {client.child_clients?.length > 0 && (
          <ul className={styles.nestedDropdown}>
            {renderNestedMenu(client.child_clients)}
          </ul>
        )}
      </li>
    ));
  };

  return (
    <div className={styles.dropdown}>
      <button
        className={styles.dropdownButton}
        type="button" // Set type to "button" to prevent form submission
        onClick={() => setShowMenu(!showMenu)}
      >
        {selectedValue} <span className={styles.arrow}>▼</span>
      </button>
      {showMenu && (
        <ul className={styles.dropdownMenu}>
          <li
            className={styles.dropdownItem}
            onClick={(e) => {
              e.stopPropagation();
              handleSelection(null, 'Hotel Group'); // Null for Hotel Group
            }}
          >
            Hotel Group
          </li>
          <li className={`${styles.dropdownItem} ${styles.nested}`}>
            Stand Alone Hotel
            <ul className={styles.nestedDropdown}>
              {renderNestedMenu(clients)} {/* Render clients */}
            </ul>
          </li>
        </ul>
      )}
    </div>
  );
};
