import React, { useState } from 'react';
import { Form } from '../form/form';
import styles from './clientForm.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import ClientCard from '../clientCard/clientCard';
import { Fieldset } from '../fieldSet/fieldSet';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../button/button';
import { TextInput } from '../form/textInput';
import { ImageUpload } from '../imageUpload/imageUpload';
import { Quil } from '../form/quil';
import { SelectInput } from '../form/selectInput';
import { NestedDropdown } from 'components/nestedDropdown/nestedDropdown';
import dataService from '../../services/dataService';

export const ClientForm = (props) => {
  const { loading, clientData, allClients, yupSchema, isEdit, state, setState, clientRemove } = props;

  const [initialValues] = useState(() => {
    return dataService.parseFormDataForClientEdit(clientData);
  });

  const [parentId, setParentId] = useState(clientData?.parent_id || null); // Tracks selected `parent_id`

  const form = useForm({
    resolver: yupResolver(yupSchema),
    mode: 'all',
    defaultValues: {
      ...clientData,
      parent_id: clientData?.parent_id || null,
      client_type_id: clientData?.parent_id ? 7 : 6, // Default client_type_id based on parent_id
      ...initialValues,
    },
  });

  const handleTypeChange = (value, label) => {
    setParentId(value); // Update parent_id
    form.setValue('parent_id', value); // Update parent_id in form state
    form.setValue('client_type_id', value ? 7 : 6); // Dynamically update client_type_id
  };

  const handleSubmitForm = (values) => {
    props.createClient({ body: { ...values, parent_id: parentId } }); // Submit with parent_id
  };

  const action = [
    <Button
      className="btn"
      type="submit"
      id="clientSubmitBtn"
      submit
      inline
      disabled={loading}
    >
      {isEdit ? 'Save changes' : 'Create client profile'}
    </Button>,
  ];

  if (isEdit) {
    action.push(
      <Button
        style={{ float: 'left' }}
        destroy
        align="left"
        type="button"
        inline
        onClick={() => {
          clientRemove(clientData);
        }}
      >
        Delete client
      </Button>,
    );
  }

  return (
    <div>
      <FormProvider {...form}>
        <Form
          onSubmit={form.handleSubmit((values) => {
            handleSubmitForm(values);
          })}
          className={styles.clientFormContainer}
          actions={[<div className="action-footer">{action}</div>]}
        >
          <div className="row g-0">
            <div className="col-lg-4 col-12 mx-0 px-0">
              <ClientCard
                client={initialValues}
                isEditable={true}
                standalone={false}
                state={state}
              />
            </div>
            <div className="col-lg-8 p-0">
              <Fieldset
                title="Hotel details"
                description="The client profile is displayed on all job advert posts when a job is posted to Holedo."
              >
                <div className="row">
                  <div className="col-lg-6">
                    <TextInput label="Name of company" type="text" required={true} name="title" />
                  </div>
                  <div className="col-lg-6">
                    <label className={styles.fieldLabel}>Hotel Type</label>
                    <NestedDropdown
                      clients={allClients}
                      selectedClient={clientData?.parent_id ? allClients.find(client => client.id === clientData.parent_id) : null}
                      onSelection={(value, label) => handleTypeChange(value, label)}
                    />
                  </div>
                </div>
                <ImageUpload
                  state={state}
                  setState={setState}
                  form={form}
                  isEdit={isEdit}
                  changeFunc={props.updateClient}
                  name="client_images"
                  label="Upload photos of the hotel"
                  description="Upload images of the hotel to display on your
          job posting when you post a job for this client."
                >
                  <Button
                    submit
                    inline
                    size="small"
                  >
                    Upload photo
                  </Button>
                </ImageUpload>
                <Quil
                  fieldDescription="A paragraph or two about the hotel. This will be displayed on all job adverts posted. This will be displayed on the company page"
                  name="description"
                  label="Hotel Description"
                  type="text"
                  placeHolder="Description"
                  required={true}
                />
                <Quil
                  fieldDescription="This will be displayed on the company page"
                  name="summary"
                  label="Hotel Summary"
                  type="text"
                  placeHolder="Hotel Summary"
                  required={true}
                  maxLength={250}
                />
                <Quil
                  fieldDescription="Paste your youtube URL."
                  name="youtube_url"
                  label="Youtube"
                  type="media"
                  placeHolder="Description"
                  required={true}
                />
                <Quil
                  fieldDescription="Paste your vimeo URL."
                  name="vimeo_url"
                  label="Vimeo"
                  type="media"
                  placeHolder="Description"
                  required={true}
                />
              </Fieldset>
              <Fieldset title="Hotel address">
                <div className={styles.addressContainer}>
                  <div className="row">
                    <div className=" col-lg-6">
                      <TextInput
                        type="text"
                        label="Address"
                        required={true}
                        name="address_line_1"
                        placeHolder="Address line 1"
                      />
                      <TextInput
                        type="text"
                        required={true}
                        name="address_line_2"
                        placeHolder="Address line 2"
                      />
                      <TextInput
                        type="text"
                        required={true}
                        name="address_line_3"
                        placeHolder="Address line 3"
                      />
                      <TextInput
                        type="text"
                        label="State, province or region"
                        required={true}
                        name="state"
                      />
                    </div>
                    <div className="col-lg-6">
                      <div className={styles.addressContainer}>
                        <TextInput type="text" label="City" required={true} name="city" />
                        <div className="row">
                          <div className="col-6 col-lg-8">
                            <SelectInput
                              name="country_id"
                              label="Country"
                              options={props.countryOptions}
                              mode="single"
                              required={true}
                              searchable={true}
                            />
                          </div>
                          <div className="col-lg-4 col-6">
                            <TextInput
                              type="text"
                              label="Area Code"
                              required={true}
                              name="area_code"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fieldset>
            </div>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};
