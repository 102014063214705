import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { isEmpty, map } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ClientCard from '@components/clientCard/clientCard';
import { Spinner } from '@components/loader/loader';
import { Section } from '@components/section/section';
import { Wizard } from '@components/wizard/wizard';
import { apiUrls } from '@constants/apiurls';
import clients from '@redux/modules/clients';
import { fetchAutoCompleteData } from '@services/autoCompleteService';
import yupSchemaService from '@services/yupSchemaService';
import { List } from '@components/list/list';
import { BaseCard } from '@components/baseCard/baseCard';
import { MdPersonAdd as AddPersonIcon } from 'react-icons/md';
import { avatarOptions } from '@components/avatar/avatar';
import { Button } from '@components/button/button';
import { routesPath } from 'constants/routesPath';
import { useDispatch } from 'react-redux';
import { updateWizardStep } from '@redux/common/commonSlice';
import { RoundButton } from 'components/roundButton/roundButton';
import { MdAdd as AddIcon } from 'react-icons/md';
import { ContactForm } from '@components/contactForm/contactForm';
import stylesOverlayForm from 'components/projectForm/overlayForm.module.scss';
import projectStyles from 'components/projectForm/projectForm.module.scss';
import stylesForms from 'components/formColumns/forms.module.scss';
import classNames from 'classnames';
import { Avatar } from '../../components/avatar/avatar';
import avatarImage from 'static/images/avatar.png';
import { MenuDivider } from '../../components/menuDivider/menuDivider';
import { GenericInput } from '../../components/genericInput/genericInput';
import { MenuSlot } from '../../components/menuSlot/menuSlot';
import { MenuScrollable } from '../../components/menuScrollable/menuScrollable';
import { MenuList } from 'react-menu-list';
import { MenuItemCard } from '../../components/menuItemCard/menuItemCard';
import { MdSearch as SearchIcon } from 'react-icons/md';
import { Heading } from '../../components/heading/heading';
import toast from 'react-hot-toast';

export const ContactFormContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { id } = props;

  const [state, setState] = useState({
    colors: [],
    positionLevels: [],
    loading: false,
    contacts: false,
    users: null,
    contactAvatar: 'asad',
    departments: [],
    addContacts: false,
    contactSearchQuery: '',
    selectedContact: null,
  });

  const getInitialData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const autoCompletedFields = [{ type: 'departments', url: apiUrls.getDepartments() }];
      const promises = autoCompletedFields.map((field) =>
        fetchAutoCompleteData(field.type, field.url),
      );

      const response = await Promise.all(promises);
      setState((prevState) => ({
        ...prevState,
        departments: response[0],
        loading: false,
      }));
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const { data: searchedContacts } = clients.list().useClientslistQuery(
    { params: '', extendedPath: apiUrls.getClientListing() },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => ({
        data: data?.data?.filter((el) => el.id),
      }),
    },
  );

  const {
    data: clientData,
    error: clientError,
    isFetching: clientLoading,
    refetch: clientsRefetch,
  } = clients.list().useClientsdetailQuery(
    { params: { id: params.clientId }, extendedPath: apiUrls.getClientDetail() },
    {
      skip: !params.clientId,
    },
  );

  const [clientUpdate, clientUpdateData] = clients.form().useClientsupdateMutation();

  useEffect(() => {
    dispatch(
      updateWizardStep({
        step: 1,
      }),
    );
    // getInitailData();
    getInitialData();
  }, []);

  const getInitailData = async () => {
    const initailDataList = [
      { url: apiUrls.getColors() },
      { type: 'language', url: apiUrls.getPositionLevels() },
    ];
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const promises = initailDataList.map((data) => fetchAutoCompleteData(data.type, data.url));
      const response = await Promise.all(promises);

      setState((prevState) => ({
        ...prevState,
        colors: response[0],
        positionLevels: response[1],
        loading: false,
      }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  useEffect(() => {
    if (clientData?.data?.client_contacts) {
      let tempContacts = [];

      clientData?.data?.client_contacts?.map((contact) => {
        tempContacts.push({
          value: contact.id,
          label: contact?.first_name + ' ' + contact?.last_name,
        });
      });
      setState((prevState) => ({
        ...prevState,
        users: tempContacts,
      }));
    }
  }, [clientData]);

  useEffect(() => {
    console.log(clientUpdateData);
    if (clientUpdateData?.isSuccess) {
      toast.success('Contact added successfully!');
      setState((prevState) => ({
        ...prevState,
        selectedContact: null,
      }));
    }
    if (clientUpdateData?.isError) {
      toast.error('Failed to add client!');
    }
  }, [clientUpdateData]);

  useEffect(() => {
    if (id || location.state?.id) {
      setState((prevState) => ({
        ...prevState,
        addContacts: true,
      }));
    }
  }, [id, location.state?.id]);

  const handleSubmit = (values) => {
    if (id) {
      values.id = id;
    }
    if (values.id) {
      let client = structuredClone(clientData?.data);
      let allContact = [...clientData.data.client_contacts];
      let index = allContact.findIndex((con) => con.id === values.id);
      values.user = allContact[index].user;
      allContact[index] = values;
      client.client_contacts = allContact;
      clientUpdate(client);
    } else {
      let contactDetail = {};
      contactDetail['id'] = params?.clientId;
      let allContact = [...clientData.data.client_contacts];

      values.id = parseInt(values.id);
      contactDetail['client_contacts'] = [...clientData.data.client_contacts, values];
      clientUpdate(contactDetail);
    }
    setState((prevState) => ({
      ...prevState,
      addContacts: false,
    }));
  };

  const handleUpdateClient = () => {
    let client = structuredClone(clientData?.data);
    let allContact = [...clientData.data.client_contacts];
    allContact.push(state.selectedContact);
    client.client_contacts = [...allContact];
    clientUpdate(client);
  };

  const deleteClientContact = (id) => {
    let contactDetail = {};
    contactDetail['id'] = params?.clientId;
    const previousContacts = clientData.data.client_contacts.map((data) => ({
      id: data.id,
    }));
    const updatedContacts = previousContacts.filter(function (contact) {
      return contact.id != id;
    });
    contactDetail['client_contacts'] = updatedContacts;
    clientUpdate(contactDetail);
  };

  const findColor = (colorId) => {
    let color = state.colors?.find((col) => col.id === colorId);
    return color?.hex;
  };

  return (
    <>
      <Wizard
        id={id}
        step={3}
        title={'Now that the client is setup. Let’s add some client contacts.'}
        icon={<AddPersonIcon size={25} />}
        formName={'contactForm'}
        action={
          state?.addProjects && (
            <>
              <Button
                inline
                regular
                //  onClick={() => finalize() && push(routes.dashboard)}
                onClick={() => navigate(routesPath.dashboard, { replace: false })}
              >
                I’ll add projects later
              </Button>
              <p style={{ color: '#686868' }}>
                You can add projects at any stage\n from the projects menu
              </p>
            </>
          )
        }
      >
        <div>
          <ClientCard isEditable={false} client={clientData?.data}>
            <Section
              title={id ? 'Update Contact' : 'ADD CLIENT CONTACT'}
              headingSize="large"
              contentVisible={true}
            >
              {clientLoading && clientUpdate.isLoading ? (
                <Spinner height={100} width={100} />
              ) : (
                <div className="form-section">
                  {state?.addContacts && (
                    <ContactForm
                      yupSchema={yupSchemaService.addContact()}
                      colors={state.colors}
                      seniorityLevels={state.positionLevels}
                      client={clientData?.data}
                      clientUpdate={clientUpdate}
                      clientUpdateData={clientUpdateData?.data}
                      loading={clientUpdateData?.isLoading || clientLoading}
                      handleSubmit={handleSubmit}
                      state={state}
                      setState={setState}
                      departments={state.departments}
                      id={id}
                    />
                  )}
                  {/* {!isEmpty(clientData?.data?.client_contacts) &&
                    !id &&
                    !clientUpdate.isLoading &&
                    !state?.addContacts && (
                      <List>
                        {map(clientData?.data?.client_contacts, (contact) => (
                          <>
                            <BaseCard
                              onRemove={() => deleteClientContact(contact?.id)}
                              key={`contact-${contact?.id}`}
                              item={contact}
                              title={contact?.first_name + ' ' + contact?.last_name}
                              size="medium"
                              avatar={{
                                ...avatarOptions(contact?.user ?? contact, 'contact'),
                                size: 40,
                              }}
                              subhead={contact?.professional_title}
                            />
                          </>
                        ))}
                      </List>
                    )} */}

                  {!state?.addContacts && !id && (
                    <div
                      className={classNames(
                        'form',
                        stylesForms.form,
                        projectStyles.projectFormContainer,
                        stylesOverlayForm.overlayForm,
                      )}
                    >
                      <div className="mt-3">
                        <Avatar size={50} src={avatarImage} />
                      </div>
                      {/* 
                      <NavItem
                zIndex={23}
                title={<Labeled toCount={clientData?.data}>Clients</Labeled>}
                hasDropdown
                active={location.pathname.includes(routesPath.clientFormPage)}
                link=""
              > */}
                      <div className="mx-3 mt-2 pt-3">
                        <MenuSlot onSelected={(e) => e.stopPropagation()}>
                          <GenericInput
                            type="text"
                            placeholder={'Add from Holedo'}
                            iconRight
                            icon={<SearchIcon />}
                            value={
                              state?.selectedContact
                                ? state.selectedContact?.first_name +
                                  ' ' +
                                  state.selectedContact?.last_name
                                : state.contactSearchQuery
                            }
                            onChange={(e) =>
                              setState((prevState) => ({
                                ...prevState,
                                contactSearchQuery: e.target.value,
                                selectedContact: null,
                              }))
                            }
                          />
                        </MenuSlot>

                        <MenuScrollable>
                          <MenuList>
                            {state.contactSearchQuery &&
                              !state.selectedContact &&
                              map(searchedContacts, (client) =>
                                map(client?.client_contacts, (contact) => (
                                  <MenuItemCard
                                    key={`c-${contact.id}`}
                                    link={routesPath.editClient(contact.id)}
                                    onClick={() => {
                                      // dispatch(handleNavToggle());
                                      // navigate(routesPath.getClientProjects(client.id), {
                                      //   replace: false,
                                      // });
                                      setState((prevState) => ({
                                        ...prevState,
                                        selectedContact: contact,
                                      }));
                                    }}
                                    name={contact.first_name + ' ' + contact?.last_name}
                                    avatar={avatarOptions(contact, 'contact')}
                                  />
                                )),
                              )}
                          </MenuList>
                        </MenuScrollable>

                        <p className="mt-3" style={{ fontSize: '0.875rem', color: '#b5bdc2' }}>
                          Can't find the person on Holedo?
                          <span
                            style={{
                              color: 'rgb(74, 174, 253)',
                              fontSize: '1rem',
                              fontWeight: '600',
                              cursor: 'pointer',
                            }}
                            className="d-block "
                            onClick={() =>
                              setState((prevState) => ({ ...prevState, addContacts: true }))
                            }
                          >
                            Enter their details manually
                          </span>
                        </p>

                        <Button
                          onClick={handleUpdateClient}
                          important
                          submit
                          type="submit"
                          disabled={!state.selectedContact || clientUpdateData?.isLoading}
                        >
                          Add client contact
                        </Button>
                        <Button
                          style={{
                            color: 'rgb(74, 174, 253)',
                            fontSize: '1rem',
                            fontWeight: '600',
                          }}
                          onClick={() =>
                            navigate(routesPath.addProjects(params?.clientId), {
                              replace: false,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* {!state.addContacts && !id && (
                    <div className="d-flex justify-content-center py-3">
                      <RoundButton
                        onClick={() =>
                          setState((prevState) => ({ ...prevState, addContacts: true }))
                        }
                      >
                        <AddIcon size={30} />
                      </RoundButton>
                    </div>
                  )} */}
                </div>
              )}
            </Section>
          </ClientCard>
        </div>
      </Wizard>
    </>
  );
};
